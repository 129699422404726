/* eslint-disable import/no-unresolved */
import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

// Components
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import { motion } from 'framer-motion'
import { useMedia } from 'react-use'

const Content = styled(ParseContent)`
  font-size: ${props => props.theme.font.size['25']};
  font-weight: ${props => props.theme.font.weight.s};
`

const Diensten = ({ business, posts }) => {
  return (
    <div className="row justify-content-between">
      {posts.map((node) => (
        <div key={node.wordpress_id} className="col-md-4 pb-5 d-flex justify-content-center">
          <Dienst business={business} fields={node} />
        </div>
      ))}
    </div>
  )
}

const StyledDienst = styled(motion.div)`
  width: 100%;
  max-width: 350px;
`

const StyledImage = styled(Image)`
  width: 100%;
  max-height: 600px;
`

const DienstTitle = styled(motion.div)`
  font-size: ${props => props.theme.font.size['30']};
  line-height: 35px;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px 80px 15px 80px;

  @media screen and (max-width: 991px) {
    padding: 5px 40px 5px 40px;
    font-size: ${props => props.theme.font.size.xm};
    line-height: 25px;
  }

  @media screen and (max-width: 768px) {
    padding: 15px 80px 15px 80px;
  }

  ${props => props.title === 'sanitaire-installaties' && css`
    background-color: ${props.theme.color.face.contrast};
    color: ${props.theme.color.text.light};
  `}
  
  ${props => props.title === 'klimaat-installaties' && css`
    background-color: ${props.theme.color.face.main};
    color: ${props.theme.color.text.light};
  `}

  ${props => props.title === 'duurzame-installaties' && css`
    background-color: ${props.theme.color.face.secondary};
    color: ${props.theme.color.text.light};
  `}
`

const Stripe = styled.div`
  width: 62px;
  height: 1px;
  
  ${props => props.title === 'sanitaire-installaties' && css`
    background-color: ${props.theme.color.face.main};
  `}
  
  ${props => props.title === 'klimaat-installaties' && css`
    background-color: ${props.theme.color.face.contrast};
  `}

  ${props => props.title === 'duurzame-installaties' && css`
    background-color: ${props.theme.color.face.main};
  `}
`

const MoreInfo = styled.div`
  font-size: ${props => props.theme.font.size.xm};
  text-transform: none;
  position: absolute;
  bottom: 30px;
  right: 40px;

  ${props => props.title === 'sanitaire-installaties' && css`
    color: ${props.theme.color.text.main};
  `}
  
  ${props => props.title === 'klimaat-installaties' && css`
    color: ${props.theme.color.text.contrast};
  `}

  ${props => props.title === 'duurzame-installaties' && css`
    color: ${props.theme.color.text.main};
  `}
`

const Dienst = ({ business, fields }) => {
  const isMobile = useMedia('(max-width: 991px)')

  return (
    <StyledDienst
      initial="init"
      whileHover="hover"
      className="position-relative"
    >
      <Link to={fields.path}>
        <StyledImage src={business ? fields.acf.preview.image : fields.acf.preview.business_image} />
        <DienstTitle
          className="text-center"
          title={fields.slug}
          variants={{
            init: { height: isMobile ? '60px' : '100px', transition: { type: 'spring', damping: 15, delay: .3 } },
            hover: { height: '100%', transition: { type: 'spring', damping: 15 } }
          }}
        >
          <motion.div 
            variants={{
              init: { height: '0px' },
              hover: { height: '50px' }
            }}
          />
          {fields.title}
          <motion.div
            variants={{
              init: { display: 'none' },
              hover: { display: 'flex' }
            }}
            className="flex-wrap justify-content-center"
          >
            <Stripe className="my-4" title={fields.slug} />
            <motion.div
              className="w-100"
              variants={{
                init: { opacity: 0, pointerEvents: "none" },
                hover: { opacity: 1, pointerEvents: "all", transition: { type: 'spring', damping: 15, delay: .3 } }
              }}
            >
              
              <Content content={fields.acf.preview.description} />
              <MoreInfo title={fields.slug}>
                Meer informatie
              </MoreInfo>
            </motion.div>
          </motion.div>
        </DienstTitle>
      </Link>
    </StyledDienst>
  )
}

export default Diensten