import React, { useState } from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'

// Blog
import BlogikDiensten from 'components/related/Diensten'
import Blogik, { BlogConsumer } from 'components/shared/Blogik'


const Title = styled.div`
  font-family: ${props => props.theme.font.family.secondary};
  font-size: ${props => props.theme.font.size['100']};
  line-height: 100px;
  color: ${props => props.theme.color.text.light};

  @media screen and (max-width: 1199px) {
    font-size: ${props => props.theme.font.size.xxxl};
  }

  @media screen and (max-width: 1050px) {
    color: ${props => props.theme.color.text.secondary};
    text-align: center;
  }
`

const StyledDiensten = styled.div``

const FilterButton = styled.div`
  cursor: pointer;

  ${props => props.selected ? css`
    background-color: ${props.theme.color.face.light};
    color: ${props.theme.color.text.secondary};
  ` : css`
    background-color: ${props.theme.color.face.secondary};
    color: ${props.theme.color.text.light};
  `}

  font-size: ${props => props.theme.font.size.xm};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0 12px 0;
  width: 185px;
  border-radius: 31px;
  border: 2px solid ${props => props.theme.color.face.secondary};
`

const Diensten = ({ className }) => {
  const [ business, setBusiness ] = useState(true)

  return (
    <StyledDiensten className={`${className ? `${className}` : ``}`}>
      <Blogik
        settings={{
          limit: 3,
          postType: 'diensten'
        }}
      >
        <BlogConsumer>
          {({ hasPosts, posts }) => {
            return (
              <div>
                {hasPosts && (
                  <>
                    <div className="row pb-5">
                      <div className="col-md-6">
                        <Title>Onze Diensten</Title>
                      </div>
                      <div className="col-md-6 d-flex justify-content-center align-items-center">
                        <FilterButton className="mr-4" onClick={() => setBusiness(true)} selected={!business}>
                          THUIS
                        </FilterButton>
                        <FilterButton onClick={() => setBusiness(false)} selected={business}>
                          ZAKELIJK
                        </FilterButton>
                      </div>
                    </div>
                    <BlogikDiensten business={business} posts={posts} />
                  </>
                )}

                {!hasPosts && (
                  <ParseContent content="Geen resultaten" />
                )}
              </div>
            )
          }}
        </BlogConsumer>
      </Blogik>
    </StyledDiensten>
  )
}

export default Diensten