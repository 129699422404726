/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

// Elements
import HeroHome from 'components/elements/home/HeroHome'
import Diensten from 'components/elements/home/Diensten'
import Referenties from 'components/elements/Referenties'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'


const Content = styled(ParseContent)`
  h2 {
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size['120']};
    line-height: 120px;

    @media screen and (max-width: 1199px) {
      font-size: ${props => props.theme.font.size.xxxl};
      line-height: 80px;
    }
  }

  p {
    font-size: ${props => props.theme.font.size['18']};
  }
`

const HomeTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <HeroHome fields={acf.banner} extraFields={acf.section_1} />

      <div className="container">
        <Diensten />
      </div>

      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <Content content={acf.section_2.description} />
          </div>
        </div>
      </div>
      
      <div className="container py-5">
        <Referenties />
      </div>
    </Layout>
  )
}

export default HomeTemplate