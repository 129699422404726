/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'

// Images
import check from 'img/check.svg'

const Hero = styled.div`
  @media (min-width: 992px) {
    min-height: 250px;
  }

  @media (max-width: 991px) {
    min-height: 150px;
  }
`

const Content = styled(ParseContent)`
  color: ${props => props.theme.color.text.light};
  font-size: ${props => props.theme.font.size['18']};
  font-weight: ${props => props.theme.font.weight.m};
  text-shadow: 0 0 4px rgba(0, 0, 0, .85);
  line-height: 35px;

  & i, em {
    font-weight: ${props => props.theme.font.weight.s};
  }

  & li {
    list-style: none;
    position: relative;
    font-size: ${props => props.theme.font.size.l};
    font-weight: ${props => props.theme.font.weight.s};

    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      left: -30px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      background-image: url(${check});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
`

const ExtraContent = styled(ParseContent)`
  font-family: ${props => props.theme.font.family.secondary};

  h2 {
    font-size: ${props => props.theme.font.size['120']};
    line-height: 80px;

    @media screen and (max-width: 576px) {
      font-size: ${props => props.theme.font.size['100']};
    }
  }
`

const ImageBackground = styled(Image)``

// const StyledImage = styled(Image)`
//   max-height: 440px;
// `

const Fade = styled.div`
  background-color: ${props => props.theme.color.face.main};
  z-index: -1;
  position: absolute;
  right: 0px;
  //max-width: 770px;
  width: 100%;
  height: auto;

  ${ImageBackground} {
    max-height: 1800px;
    min-height: 1200px;
    height: 100%;
    max-width: 785px;
    opacity: 0.10;
  }

  @media screen and (max-width: 767px) {
    padding-left: 0;
  }
`

const Background = styled.div`
  height: auto;

  @media screen and (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
  }
`

const ContentRow = styled.div`
  padding: 50px 0 0 0;
`

const ImageContainer = styled.div`
  position: relative;
`

const ExtraImage = styled(Image)`
  position: absolute;
  width: 770px;
  left: 15px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`

const ExtraContentContainer = styled.div`
  margin-left: -15px;
  padding-left: 0;
  padding-right: 0;

  @media screen and (max-width: 767px) {
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
`

const White = styled.div`
  position: absolute;
  width: 770px;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 15px;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 95%, rgba(255,255,255,1) 100%);

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const VideoContainer = styled.div`
  height: 538px;
  width: 1540px;
  left: -215px;
  position: relative;

  @media screen and (max-width: 991px) {
    height: 250px;
    width: 100%;
    left: 0;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }

  @media screen and (max-width: 500px) {
    height: auto;
  }
`

const StyledVideo = styled.iframe`
  border: none;
`

const HeroHome = ({ fields, extraFields }) => {
  return (
    <Hero>
      <Background className="row">
        <div className="col-md-6 position-relative pr-0">
          <Fade>
            <ImageBackground src={fields.background} aboveFold />
          </Fade>
        </div>
      </Background>
      <div className="container py-5">
        <ContentRow className="row">
          <div className="col-12 pb-lg-5">
            <VideoContainer>
              <StyledVideo
                src="https://player.vimeo.com/video/504803573?controls=false&autoplay=true&muted=true&loop=true"
                width="100%"
                height="100%"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              />
            </VideoContainer>
          </div>
          <div className="col-md-6 pt-md-5 pr-md-5 pb-md-0 pb-5">
            <Content content={fields.description} />
          </div>
          <ExtraContentContainer className="col-md-6">
            <ExtraContent className="pb-2" content={extraFields.description} />
            <ImageContainer>
              <White />   
              <ExtraImage src={extraFields.image} aboveFold />
            </ImageContainer>            
          </ExtraContentContainer>
        </ContentRow>
        
      </div>
    </Hero>
  )
}

export default HeroHome